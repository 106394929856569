/*
 * Add get/set cursor position methods to jQuery.
 */

module.exports = ($) => {
  // http://stackoverflow.com/questions/499126/jquery-set-cursor-position-in-text-area
  $.fn.setCursorPosition = function (pos) {
    if ($(this).get(0).setSelectionRange) {
      $(this).get(0).setSelectionRange(pos, pos);
    } else if ($(this).get(0).createTextRange) {
      const range = $(this).get(0).createTextRange();
      range.collapse(true);
      range.moveEnd("character", pos);
      range.moveStart("character", pos);
      range.select();
    }
    return $(this);
  };

  // http://stackoverflow.com/questions/1891444/how-can-i-get-cursor-position-in-a-textarea
  $.fn.getCursorPosition = function () {
    let pos = 0;
    const el = $(this).get(0);
    // IE Support
    if (document.selection) {
      el.focus();
      const Sel = document.selection.createRange();
      const SelLength = document.selection.createRange().text.length;
      Sel.moveStart("character", -el.value.length);
      pos = Sel.text.length - SelLength;
    }
    // Firefox support
    else if (el.selectionStart || el.selectionStart === "0") {
      pos = el.selectionStart;
    }

    return pos;
  };
};
