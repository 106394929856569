module.exports = function users($, M) {
  // Setup
  M.users = {};

  $.extend(true, M.users, {
    get_context(user) {
      const user_dict = $.extend(true, {}, user);
      user_dict.is_self = user_dict.id === M.user.id;
      return user_dict;
    },

    mempal_add_press() {
      const to_user_id = $(this).dataAttr("user-id");
      const $button = $(this);

      $.ajax({
        url: "/ajax/mempal_add/",
        type: "POST",
        data: {
          to_user_id,
        },
        success(data) {
          if (data.success) {
            _(M.users).emit("follow-press", [data]);
            M.users.mempal_add_success($button, to_user_id, data);
          } else if (data.error) {
            M.modal.error(data.error);
          }
        },
      });
    },

    mempal_add_success($button, to_user_id, data) {
      const html = M.renderer.render("mempal-button", data.user);
      const $new_button = $(html);
      $button = $button.replaceWith($new_button);

      const offset = data.user.current_follows ? 1 : -1;

      if (data.user.current_follows) {
        $new_button.addClass("added");
      }

      // Update own following counts
      $(`[data-user-id="${M.user.id}"]`)
        .find('[data-role="following-count"]')
        .each(() => {
          const $display = $(this);
          const current = Number.parseInt($display.text(), 10);
          $display.text(current + offset);
        });

      // Update targeted user's follower counts
      $(`[data-user-id="${to_user_id}"]`)
        .find('[data-role="follower-count"]')
        .each(() => {
          const $display = $(this);
          const current = Number.parseInt($display.text(), 10);
          $display.text(current + offset);
        });
    },
  });

  M.renderer.ready(() => {
    $(document).on("click", ".mempal-button", M.users.mempal_add_press);
  });
};
