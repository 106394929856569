/**
 * This file is included on every page and is used to display a cookie banner consent message to the user
 * upon first loading the application
 */

module.exports = ($, M, i18n) => {
  return () => {
    const urlParams = new URL(window.location.href).searchParams;
    if (urlParams.get("headless") === null) {
      // The path does not contain the locale if the language is english
      const languagePath = M.LANG && M.LANG !== "en" ? `/${M.LANG}` : "";
      const cookiePolicyLink = `https://www.memrise.com${languagePath}/cookies`;
      const unsupportedLocales = [
        "ar",
        "es-mx",
        "hi",
        "id",
        "nl",
        "no",
        "pt",
        "sv",
      ];
      const cookieBannerText = unsupportedLocales.includes(M.LANG)
        ? "This website uses cookies and other technologies to enhance your experience and for web analytics. To find out more or change your choices, view our <a>Cookie Policy</a>. By clicking <strong>I agree</strong> you consent to the use of such cookies."
        : _t("web_cookie_banner_text");
      const cookieBanner = cookieBannerText.replace(
        "<a>",
        `<a href=${cookiePolicyLink}>`,
      );
      const cookieBannerButton = unsupportedLocales.includes(M.LANG)
        ? "I agree"
        : _t("web_cookie_banner_button");

      window.cookieconsent.initialise({
        palette: {
          popup: { background: "#FFFFFF" },
          button: { background: "#2B3648" },
        },
        layout: "memrise-cookie-banner",
        layouts: {
          "memrise-cookie-banner": "{{message}}{{allow}}",
        },
        theme: "corporate",
        domain: M.DOMAIN,
        name: "memrise-cookie-consent",
        content: {
          message: cookieBanner,
          allow: cookieBannerButton,
        },
        location: false,
        href: cookiePolicyLink,
      });
    }
  };
};
