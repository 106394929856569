module.exports = (_, Class, $, M) => {
  const settings = {
    mouseleave_threshold: 500,
    trigger_spacing: 20,
  };
  const selectors = {
    hovercard: ".hovercard",
  };
  const class_names = {
    directions: {
      left: "hovercard-left",
      right: "hovercard-right",
      top: "hovercard-top",
      bottom: "hovercard-bottom",
    },
  };

  const HoverCard = Class.create({
    initialize($trigger, user, direction) {
      this.user = user;
      this.direction = direction || "right";
      this.$trigger = $trigger;
      this.update();
    },

    set_user(user) {
      this.user = user;
      this.update();
    },

    update() {
      if (this.$card) {
        this.destroy();
      }
      this.$card = this.render();
      this.align();
      this.$elements = this.$card.add(this.$trigger);
      this.$elements.data("hovercard", this);
      this.bind();
    },

    render() {
      let mus_user = null;

      if (this.user) {
        mus_user = M.users.get_context(this.user);
        mus_user = this.setUpBadgeInfo(mus_user);
      }

      const $card = $(
        MEMRISE.renderer.render("hovercard", { the_user: mus_user }),
      );
      $card.appendTo("body");

      $('*[data-toggle="tooltip"]').tooltip();

      return $card;
    },

    setUpBadgeInfo(user_data) {
      const { badges } = user_data;

      if (badges.goal_streak) {
        const level = badges.goal_streak.level % 35;
        let color = "";

        if (level > 29) color = "c5";
        else if (level > 19) color = "c4";
        else if (level > 14) color = "c3";
        else if (level > 6) color = "c2";
        else if (level > 2) color = "c1";
        else color = "c0";

        user_data.badges.goal_streak.color = color;
      }

      return user_data;
    },

    get_alignment(direction) {
      const trigger_pos = this.$trigger.offset();
      let x = 0;
      let y = 0;

      if (direction === "top" || direction === "bottom") {
        x =
          trigger_pos.left +
          (this.$trigger.outerWidth() - this.$card.outerWidth()) / 2;

        if (direction === "top") {
          y =
            trigger_pos.top -
            this.$card.outerHeight() -
            settings.trigger_spacing;
        } else {
          y =
            trigger_pos.top +
            this.$trigger.outerHeight() +
            settings.trigger_spacing;
        }
      }

      if (direction === "left" || direction === "right") {
        y =
          trigger_pos.top +
          (this.$trigger.outerHeight() - this.$card.outerHeight()) / 2;

        if (direction === "left") {
          x =
            trigger_pos.left -
            this.$card.outerWidth() -
            settings.trigger_spacing;
        } else {
          x =
            trigger_pos.left +
            this.$trigger.outerWidth() +
            settings.trigger_spacing;
        }
      }

      return {
        x,
        y,
      };
    },

    align() {
      let pos = this.get_alignment(this.direction);
      const win = $(window);
      const win_width = win.width();
      const win_height = win.height();
      const win_origin_x = win.scrollLeft();
      const win_origin_y = win.scrollTop();
      let re_align = false;
      let used_direction = this.direction;
      let i;

      if (this.direction === "left" && pos.x < win_origin_x) {
        re_align = "right";
      } else if (
        this.direction === "right" &&
        pos.x + this.$card.outerWidth() > win_origin_x + win_width
      ) {
        re_align = "left";
      } else if (this.direction === "top" && pos.y < win_origin_y) {
        re_align = "bottom";
      } else if (
        this.direction === "bottom" &&
        pos.y + this.$card.outerHeight() > win_origin_y + win_height
      ) {
        re_align = "top";
      }

      if (re_align) {
        pos = this.get_alignment(re_align);
        used_direction = re_align;
      }

      for (i = 0; i < class_names.directions.length; i += 1) {
        this.$card.removeClass(class_names.directions[i]);
      }
      this.$card.addClass(class_names.directions[used_direction]);

      this.$card.css({ left: pos.x, top: pos.y });
    },

    bind() {
      this.$elements.on("mouseenter", () => {
        this.on_mouse_enter();
      });

      this.$elements.on("mouseleave", () => {
        this.on_mouse_leave();
      });
    },

    destroy() {
      this.$elements.off("mouseenter").off("mouseleave");
      this.$card.remove();
      this.$trigger.removeData("hovercard");
    },

    on_mouse_enter() {
      if (this.hover_timer) {
        clearTimeout(this.hover_timer);
      }
    },

    on_mouse_leave() {
      if (this.hover_timer) {
        clearTimeout(this.hover_timer);
      }

      this.hover_timer = setTimeout(() => {
        this.destroy();
      }, settings.mouseleave_threshold);
    },
  });

  if (!_.has(M, "ui")) {
    M.ui = {};
  }
  M.ui.HoverCard = HoverCard;

  // Bindings
  const cached_users = {};

  M.renderer.ready(() => {
    $(document).on(
      "mouseenter",
      '[data-role="hovercard"][data-user-id]:visible',
      function () {
        const $trigger = $(this);
        const user_id = Number.parseInt($trigger.dataAttr("user-id"), 10);
        const dir = $trigger.dataAttr("direction");
        let hovercard;
        let user = null;

        if (cached_users.hasOwn(user_id)) {
          user = cached_users[user_id];
        }

        if (typeof $trigger.data("hovercard") === "undefined") {
          $(selectors.hovercard).each(function () {
            $(this).data("hovercard").destroy();
          });
          hovercard = new M.ui.HoverCard($trigger, user, dir);
        } else {
          hovercard = $trigger.data("hovercard");
        }

        if (user === null) {
          $.ajax({
            url: "/ajax/user/get/",
            type: "GET",
            data: { user_id, with_leaderboard: true },
            success(data) {
              cached_users[user_id] = data.user;
              hovercard.set_user(data.user);
            },
          });
        }
      },
    );
  });

  _(M.users).on("follow-press", (data) => {
    // Update our knowledge about this user, importantly follow status.
    if (_.has(cached_users, data.user.id)) {
      cached_users[data.user.id] = $.extend(
        cached_users[data.user.id],
        data.user,
      );
    }
  });
};
