module.exports = function renderer($, M, _, Handlebars, markdown) {
  Handlebars.registerHelper("debug", function (optionalValue) {
    console.log("Current Context");
    console.log("====================");
    console.log(this);

    if (optionalValue) {
      console.log("Value");
      console.log("====================");
      console.log(optionalValue);
    }
  });

  // biome-ignore lint/suspicious/noRedeclare: Not clear how this actually works
  const renderer = {
    /*
         Preparation functions
         */
    loaded: false,
    ready_function_stack: [],

    ready(funky) {
      // A replacement for jQuery's document ready: for parts of the
      // site that require the renderer.
      // Only triggered after loading, which is cached, so should
      // occur microscopically after document.ready
      if (renderer.loaded) {
        funky();
      } else {
        renderer.ready_function_stack.push(funky);
      }
    },

    load(templates) {
      // Once.
      if (renderer.loaded) {
        return;
      }

      if (_.has(templates, "partials")) {
        // Pre-compile partials - slow and only for dev use
        $.each(templates.partials, (name, template) => {
          Handlebars.registerPartial(
            name,
            Handlebars.compile(template, {
              preventIndent: true, // otherwise hb screws up preformatted text in partials
            }),
          );
        });
      } else if (_.has(templates, "compiled_partials")) {
        templates.compiled_partials();
      }

      renderer.set_default_context();

      renderer.loaded = true;
      // Run the on-ready functions
      for (let i = 0; i < renderer.ready_function_stack.length; i += 1) {
        renderer.ready_function_stack[i]();
      }
      renderer.ready_function_stack = [];
    },

    /**
     * Sanitize url by deleting leading slash if necessary
     * and add it to the static url
     * @param {String} url a media url
     * @return {String} the same url with a leading slash
     *     if it doesn't already have one
     */
    fixMediaUrl(url) {
      if (url.match(/^https?:\/\//)) {
        return url;
      }

      return M.STATIC_URL + url.replace(/^\/?/, "");
    },

    escape(string) {
      const entityMap = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;",
        "/": "&#x2F;",
      };

      return String(string).replace(/[&<>"'\/]/g, (s) => entityMap[s]);
    },

    set_default_context() {
      renderer.default_context = {
        DEBUG: M.DEBUG,
        STATIC_URL: M.STATIC_URL,
        user: M.user,
        DOMAIN: M.DOMAIN,
      };
    },

    /*
         Rendering & Handlebarsification
         */
    render(template, context, data) {
      const full_context = $.extend({}, renderer.default_context, context);
      return Handlebars.partials[template](full_context, { data });
    },

    // Allowed tags from markdown in rich_format (jQuery selector)
    allowed_tags: "p,strong,em,pre,code",

    rich_format(string) {
      // Turns string into html as per memrise-markdown styles

      // *  Custom img: tag
      // Old hardcoded static urls (NEED MIGRATING)
      string = string.replace(
        /img:http:\/\/www.memrise.com\/static\//g,
        `img:${M.STATIC_URL}`,
      );
      string = string.replace(
        /img:http:\/\/memrise.com\/static\//g,
        `img:${M.STATIC_URL}`,
      );
      string = string.replace(/img:\/static\//g, `img:${M.STATIC_URL}`);
      // Tags: in a hacky way. turn into code blocks temporarily so that we get the url through markdown un-modified, then later delete code blocks.
      string = string.replace(/img:([^\s<]+)/g, "`img:$1`");

      // *  Markdown, filtered to remove all non-allowed tags
      const marked_down = markdown.toHTML(string);
      const $html = $("<div>").html(marked_down);
      // Filter the allowed tags inside the DOM element
      $html.find("*").each(function () {
        if (!$(this).is(renderer.allowed_tags)) {
          $(this).remove();
        }
      });
      let html = $.trim($html.html());
      if (html === "") {
        html = "<p>&nbsp;</p>";
      }

      // Second step for img:
      html = html.replace(
        /img:\s*([^\s<]+)/g,
        "<img class='img-tag' src='$1' />",
      );
      // *  Custom embed: tag for youtubes etc.
      html = html.replace(
        /embed:\s*([^\s<]+)/g,
        "<a class='embed' href='$1' target='_blank'>$1</a>",
      );

      return html;
    },

    do_embeds($elem) {
      // Takes a jQuery element containing rich_formatted code and
      // replaces the embed: links (a.embed) with embedly + some
      // other fiddles.
      const $embeds = $elem.find("a.embed");

      // VZAARs, through oembed.
      // (are we ever going to get away from these guys?)
      const $vzaars = $embeds.filter('*[href*="vzaar.com"]');
      $vzaars.oembed(null, { autoplay: false });

      // BRAINSHARKS, through custom code.
      // (are we ever going to get away from these guys?)
      const $brainsharks = $embeds.filter('*[href*="brainshark.com"]');
      $brainsharks.each(function () {
        const url = $(this).attr("href");
        const pid = url.match(/\d+$/)[0];
        $(this).replaceWith(
          `<iframe src="http://www.brainshark.com/brainshark/vu/view.asp?pi=${pid}&dm=5&pause=1&nrs=1" frameborder="0" scrolling="no" style="border:1px solid #999999; height:400px;"></iframe>`,
        );
      });

      // GLOGSTERS, through custom code
      const $glogsters = $embeds.filter('*[href*="glogster.com"]');
      $glogsters.each(function () {
        const url = $(this).attr("href");
        const id = url.match(/glog\/(\w+)/)[1];
        const tall = url.match(/tall=1/) !== null;
        const height = tall ? 670 : 366;

        $(this).replaceWith(
          `<iframe src="http://www.glogster.com/glog/${id}" height="${height}" width="495" name="glogster-embed-glog" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" style="overflow: hidden;"></iframe>`,
        );
      });

      // The rest, through embedly.
      $embeds
        .not($vzaars)
        .not($brainsharks)
        .not($glogsters)
        .embedly({
          // This should prevent z-index issues.
          embedly_wmode: "window",
          key: "eb0a2940f03711e0aff54040d3dc5c07",
          maxHeight: 360,
          // Should probably be synced with mem-width
          maxWidth: 495,
        })
        .bind("embedly-oembed", function () {
          // Set a tiny timeout to modify the url if it's a YouTube
          // video. Running it right away doesn't seem to work.
          const $parent = $(this).parent();
          $.doTimeout(10, () => {
            $parent.find('iframe[src*="youtube.com"]').each(function () {
              // Rewrite "youtu.be" links so that we can add settings
              $(this).attr(
                "src",
                `${$(this).attr(
                  "src",
                )}&rel=0&showsearch=0&showinfo=0&modestbranding=1&disablekb=1`,
              );
            });
          });
        });
    },

    get_pool_context(pool) {
      const pool_dict = $.extend(true, {}, pool);
      pool_dict.columns_list = [];
      $.each(pool.columns, (key, column) => {
        const col = $.extend(true, {}, column);
        col.key = key;
        pool_dict.columns_list.push(col);
      });
      return pool_dict;
    },
  };

  return renderer;
};
