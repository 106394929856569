module.exports = (_, $, M) => {
  M.courses = {
    unenroll_press() {
      const course_id = $(this).dataAttr("course-id");

      M.modal.yes_no(_t("Quit this course"), M.trans.quitCourse, () => {
        M.courses.unenroll(course_id);
      });

      return false;
    },

    unenroll(course_id) {
      $.ajax({
        url: "/ajax/courses/quit/",
        data: { course_id },
        type: "POST",
        success(response) {
          if (response.success) {
            _(M.courses).emit("unenrolled", [course_id]);
          }
        },
      });
    },
  };

  M.renderer.ready(() => {
    $(document).on(
      "click",
      '[data-role="course-unenroll"]',
      M.courses.unenroll_press,
    );
  });
};
