module.exports = (_, Class, $, M, KEY_CODES) => {
  const ModalSlides = Class.create({
    initialize(template_name, options) {
      const base_class_name = `modal-slides-${template_name}`;

      if (!options) {
        options = {};
      }

      this.show_overlay = _.has(options, "show_overlay")
        ? options.show_overlay
        : true;

      this.closed = false;
      this.on_close = options.on_close || null;

      this.context = {
        title: options.title || null,
        icon: options.icon || null,
        class_names: `${base_class_name} ${options.class_names || ""}`,
        skippable: options.skippable || null,
      };

      this.template_name = template_name;
    },

    open() {
      this.render();
      this.bind();
      this.current = -1;
      this.next();
      return this;
    },

    has_slide(index) {
      return index < this.$slides.length && index >= 0;
    },

    next() {
      const index = this.current + 1;

      if (!this.has_slide(index)) {
        this.close();
        return;
      }

      this.$slides.hide().eq(index).show();
      this.current = index;
      this.$elem.modal("show");

      if (this.show_overlay) {
        this.$backdrop.show();
      }

      return this;
    },

    close() {
      this.$elem.remove();

      if (this.show_overlay) {
        this.$backdrop.fadeOut(200, function () {
          $(this).remove();
        });
      } else {
        $(this).remove();
      }

      if (typeof this.on_close === "function") {
        this.on_close();
      }
      this.closed = true;
      return this;
    },

    bind() {
      this.$elem.on("hidden.bs.modal", () => {
        this.next();
      });

      $("body").on("keydown", (e) => {
        if (this.closed === false && e.keyCode === KEY_CODES.ENTER) {
          this.$elem.modal("hide");
          e.preventDefault();
        }
      });

      this.$elem.on("click", '[data-role="show-next"]', (e) => {
        this.$elem.modal("hide");
        e.preventDefault();
      });

      this.$elem.on("click", '[data-role="skip"]', (e) => {
        this.current = this.$slides.length;
        this.$elem.modal("hide");
        e.preventDefault();
      });
    },

    render() {
      this.context.slides = M.renderer.render(
        `modal-slides-${this.template_name}`,
      );
      this.context.slides = this.context.slides;
      this.$elem = $(M.renderer.render("modal-slides", this.context));
      this.$backdrop = this.show_overlay
        ? $(M.renderer.render("modal-backdrop"))
        : null;
      this.$elem.add(this.$backdrop).appendTo("body");
      this.$slides = this.$elem.find('[data-role="slides"]>li');
      this.$elem.modal({ backdrop: false });
    },
  });

  if (!Object.hasOwn(M, "ui")) {
    M.ui = {};
  }
  M.ui.ModalSlides = ModalSlides;

  $(document).ready(() => {
    $("body").on("click", '[data-role="open-modal-slides"]', function () {
      new MEMRISE.ui.ModalSlides($(this).dataAttr("type"), {
        skippable: true,
        on_close() {},
      }).open();
    });
  });
};
