module.exports = ($, M) => {
  let isProfileLoaded = false;

  // Headless version (hidden header and footer) support
  const urlParams = new URL(window.location.href).searchParams;
  if (urlParams.get("headless") !== null) {
    $("body").addClass("headless");
  }

  /**
   * Profile dropdown
   */
  const $profileInner = $(".header-profile-inner");
  const $profileDetails = $(".profile-details");
  const $headerInfoCard = $(".header-info-card");
  const $dropdownArrow = $(".header-dropdown-arrow");

  function toggleProfileDropDown() {
    $profileInner.toggleClass("is-active");
    $headerInfoCard.toggleClass("is-active");
    $dropdownArrow.toggleClass("is-active");

    if (!isProfileLoaded) {
      $.get("/ajax/user/profile/", (resp) => {
        resp.user = M.user.username;
        const html = M.renderer.render("header-profile-content", resp);
        $profileDetails.html(html);
        animateProgressBar();
      });
      isProfileLoaded = true;
    }
  }

  function animateProgressBar() {
    const $profileProgressBar = $(".js-progress-bar");
    const progress = $profileProgressBar.attr("data-progress");
    $profileProgressBar.animate({
      width: `${progress * 100}%`,
    });
  }

  $(".js-info-toggle").on("click", (e) => {
    toggleProfileDropDown();
    e.stopPropagation(); // needed for document click
  });

  $(document).on("click", (e) => {
    if (
      $profileInner.hasClass("is-active") &&
      $(e.target).closest("ul")[0] !== $profileInner[0]
    ) {
      toggleProfileDropDown();
    }
  });
};
