module.exports = ($, M, m, Cookies) => {
  const catchedErrorMessages = ["Login required.", "CSRF failed."];

  // CSRF fix : see javascript_user.html
  $(document).ready(() => {
    if (
      M.csrftoken &&
      Cookies.get("csrftoken") !== M.csrftoken &&
      M.csrftoken !== "NOTPROVIDED"
    ) {
      Cookies.set("csrftoken", M.csrftoken);
    }
  });

  $(document).ajaxError((event, request) => {
    if (
      request.status === 403 &&
      catchedErrorMessages.indexOf(request.responseText) > -1
    ) {
      M.modal.error(
        _t(
          `Uh-oh, something went wrong. Click OK to resume your session (${request.responseText})`,
        ),
        () => {
          window.location.href = "/login";
        },
      );
    }
  });

  // Add in CSRF header to every AJAX request. Copied from Django CSRF ref.
  // https://docs.djangoproject.com/en/dev/ref/contrib/csrf/
  const csrfSafeMethod = (method) => {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
  };

  const sameOrigin = (url) => {
    // test that a given url is a same-origin URL
    // url could be relative or scheme relative or absolute
    const { host } = document.location; // host + port
    const { protocol } = document.location;
    const sr_origin = `//${host}`;
    const origin = protocol + sr_origin;
    // Allow absolute or scheme relative URLs to same origin
    return (
      url === origin ||
      url.slice(0, origin.length + 1) === `${origin}/` ||
      url === sr_origin ||
      url.slice(0, sr_origin.length + 1) === `${sr_origin}/` ||
      // or any other URL that isn't scheme relative or absolute i.e relative.
      !/^(\/\/|http:|https:).*/.test(url)
    );
  };

  function xhrConfig(xhr, settings) {
    if (!csrfSafeMethod(settings.type) && sameOrigin(settings.url)) {
      // Send the token to same-origin, relative URLs only.
      // Send the token only if the method warrants CSRF protection
      // Using the CSRFToken value acquired earlier
      xhr.setRequestHeader("X-CSRFToken", M.csrftoken);
    }
  }

  $.ajaxSetup({
    cache: false,
    dataType: "json",
    beforeSend: xhrConfig,
  });

  m.post = (opts) => {
    opts.config = xhrConfig;
    opts.method = "POST";
    return m.request(opts);
  };

  m.delete = (opts) => {
    opts.config = xhrConfig;
    opts.method = "DELETE";
    return m.request(opts);
  };
};
