module.exports = function modal($, M) {
  const $window = $(window);

  M.modal = {
    error(message, on_hide, close, _buttons) {
      M.modal.info(_t("Whoops."), message, on_hide);
    },
    content(options, callback, onHiddenCallback) {
      const defaults = {
        template: "",
        showHeader: false,
        showFooter: false,
        headerText: "",
        backdrop: true,
        keyboard: false,
        modalId: "",
        shadow: false,
        seeThru: true,
        fadeTime: "fast",
        removeOnHidden: false,
        templateVars: {},
      };
      const opts = $.extend({}, defaults, options);

      M.renderer.ready(function readyCallback() {
        const context = {
          title: opts.headerText,
          content: opts.template,
          testVal: opts.testVal,
          id: opts.modalId,
          shadow: opts.shadow,
          fade: true,
          fadeTime: opts.fadeTime,
          seeThru: opts.seeThru,
          templateVars: opts.templateVars,
        };
        const html = M.renderer.render("modal-content-parent", context);
        const $modal = $(html).appendTo("body");

        $modal
          .on("show.bs.modal", () => {
            if (!opts.showHeader) $(".modal-header", $modal).hide();
            if (!opts.showFooter) $(".modal-footer", $modal).hide();
          })
          .on("shown.bs.modal", () => {
            M.modal.position($modal);
            $(window).resize(() => {
              M.modal.position($modal);
            });
            $modal.css({
              display: "none",
              visibility: "visible",
            });
          })
          .on("hidden.bs.modal", () => {
            if (opts.removeOnHidden) $modal.remove();
            if (_.isFunction(onHiddenCallback)) onHiddenCallback();
          })
          .modal({
            backdrop: opts.backdrop,
            keyboard: opts.keyboard,
          });
      });
      $(`#${opts.modalId}.modal`).fadeIn(opts.fadeTime);
      if (_.isFunction(callback)) callback();
    },
    position(modal) {
      const $content = modal.find(".modal-content").first();
      const $dialog = modal.find(".modal-dialog").first();
      const top = Math.abs($window.height() - $content.height()) / 2;
      $dialog.css({
        top: top - 30, // margin is 30 by default
      });
    },
    info(title, message, on_hide, onBeforeShow) {
      M.renderer.ready(function readyCallback() {
        const context = { title, message };
        const html = M.renderer.render("modal-info", context);
        const $modal = $(html).appendTo("body");

        if (typeof onBeforeShow === "function") {
          onBeforeShow();
        }

        $modal
          .on("hide.bs.modal", () => {
            if (typeof on_hide === "function") {
              on_hide();
            }
          })
          .on("shown.bs.modal", () => {
            $modal.find(".btn-primary").focus();
          })
          .on("hidden.bs.modal", function () {
            $(this).remove();
          })
          .modal("show");
      });
    },

    deletion_confirmation(
      title,
      message,
      information_on_string_to_type,
      string_to_type,
      cancel_text,
      ok_text,
      on_yes,
    ) {
      M.renderer.ready(() => {
        const html = M.renderer.render("modal-deletion-confirmation", {
          title,
          message,
          information_on_string_to_type,
          string_to_type,
          cancel_text,
          ok_text,
        });
        const $yes_no = $(html).appendTo("body");
        const $error_message = $yes_no.find(".js-error-message");

        $yes_no.find(".btn-no").on(
          "click",
          _.once(() => {
            $yes_no.modal("hide");
            return false;
          }),
        );

        $yes_no.find(".btn-yes").on("click", () => {
          const $input = $yes_no.find(".js-confirm-message-input");
          const input = $input.val();

          if (input !== string_to_type) {
            $error_message.removeClass("hidden");
          } else {
            $yes_no.modal("hide");
            if (typeof on_yes === "function") {
              on_yes();
            }
          }
          return false;
        });

        $yes_no
          .on("shown.bs.modal", () => {
            $yes_no.find(".btn-primary").focus();
          })
          .on("hidden.bs.modal", function () {
            $(this).remove();
          })
          .modal("show", { keyboard: false });
      });
    },

    yes_no(title, message, on_yes, on_no) {
      M.renderer.ready(() => {
        const html = M.renderer.render("modal-yesno", { title, message });
        const $yes_no = $(html).appendTo("body");

        $yes_no.find(".btn-no").on(
          "click",
          _.once(() => {
            $yes_no.modal("hide");
            if (typeof on_no === "function") {
              on_no();
            }
            return false;
          }),
        );

        $yes_no.find(".btn-yes").on(
          "click",
          _.once(() => {
            $yes_no.modal("hide");
            if (typeof on_yes === "function") {
              on_yes();
            }
            return false;
          }),
        );

        $yes_no
          .on("shown.bs.modal", () => {
            $yes_no.find(".btn-primary").focus();
          })
          .on("hidden.bs.modal", function () {
            $(this).remove();
          })
          .modal("show", { keyboard: false });
      });
    },

    $spinner: null,

    spinner_open(title, message, fade) {
      if (M.modal.$spinner) {
        return false;
      }

      M.renderer.ready(() => {
        const context = {
          title,
          message,
          fade: fade === undefined ? true : fade,
        };
        const html = M.renderer.render("modal-spinner", context);
        M.modal.$spinner = $(html).appendTo("body");
        M.modal.$spinner.modal("show", { keyboard: false });
      });

      return true;
    },

    spinner_close() {
      if (!M.modal.$spinner) {
        return false;
      }

      M.modal.$spinner
        .on("hide.bs.modal", function () {
          $(this).remove();
        })
        .modal("hide");

      M.modal.$spinner = null;

      return true;
    },
  };
};
