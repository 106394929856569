module.exports = ($, _) => {
  $.fn.dataAttr = function (name, otherwise) {
    // Given name 'this', returns attr('data-this');
    // if traverseUp is given (defaults to true), continue traversing
    // upwards through DOM tree until a parent element is found with the
    // attr; closest wins in case of conflict.
    const nm = `data-${name}`;
    const elem = this.closest(`[${nm}]`);

    if (elem.length === 0) {
      return otherwise;
    }
    const attr = elem.attr(nm);
    if (typeof attr === "undefined") {
      return otherwise;
    }
    return attr;
  };

  $.fn.dataDict = function (name, otherwise) {
    // little helper to extract dict off multiple data-attributes with pattern data-dictname-*
    const nm = `data-${name}-`;
    const result = {};
    $(this).each(function () {
      $.each(this.attributes, function () {
        if (this.specified && _.contains(this.name, nm)) {
          const parts = this.name.split("=");
          const data_key = parts[0].substring(nm.length);
          result[data_key] = this.value;
        }
      });
    });
    if (result.length === 0) return otherwise;
    return result;
  };
};
