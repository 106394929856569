module.exports = ($, M) => {
  const popup = {};
  const current_url = M.DOMAIN + window.location.pathname;
  const popupBaseOptions = "status=no, toolbar=no, menubar=no, location=no";

  $("body").on("click", '[data-role="twitter-share"]', function (e) {
    M.sharing.twitterShareClick($(this));
    e.preventDefault();
  });

  M.sharing = M.sharing || {};

  function getPopupOptions() {
    popup.width = 550;
    popup.height = 450;
    popup.left = ($(window).width() - popup.width) / 2;
    popup.top = ($(window).height() - popup.height + 50) / 2;
    popup.str = `${popupBaseOptions}, width=${popup.width}, height=${popup.height}, top=${popup.top}, left=${popup.left}`;
  }

  M.sharing.twitterShareClick = (target) => {
    const url = target.dataAttr("url") || current_url;
    const tweetText = target.dataAttr("tweet") || "Check this out!  ";
    const via = target.dataAttr("via") || "memrise";
    const hashTags = target.dataAttr("hashtags") || "";

    getPopupOptions();

    const completeUrl = `http://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText,
    )}&url=${encodeURIComponent(url)}&via=${encodeURIComponent(
      via,
    )}&hashtags=${encodeURIComponent(hashTags)}`;

    window.open(completeUrl, "Tweet", popup.str);
  };
};
