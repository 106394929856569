module.exports = ($, M, m, _) => {
  function LeaderboardRow(data) {
    this.photo = m.prop(data.photo);
    this.username = m.prop(data.username);
    this.position = m.prop(data.position);
    this.points = m.prop(data.points);
    this.id = m.prop(data.uid);
    this.following = m.prop(data.following);
    this.hovering = m.prop(false);
  }

  function renderLeaderboardRows(ctrl) {
    return m(
      "ul.leaderboard-rows",
      ctrl.vm.rows().map((row, index) => {
        const isMyRow = row.id() === M.user.id;
        const rowCls = isMyRow ? "leaderboard-row current" : "leaderboard-row";
        const pointsCls =
          index === 0 && !isMyRow ? "row-points best" : "row-points";

        return m("li", { key: row.id(), class: rowCls }, [
          m("span.row-pic", [
            ctrl.vm.largeBoard() ? m("strong", `${row.position()}.`) : "",
            m("img", { src: row.photo() }),
          ]),
          m("span.row-username", [
            ctrl.vm.largeBoard() ? "" : m("strong", `${row.position()}.`),
            m(
              "span",
              {
                "data-role": "hovercard",
                "data-user-id": row.id(),
                "data-direction": "right",
              },
              m(`a[href=/user/${row.username()}].clickable`, row.username()),
            ),
            ctrl.vm.showFollow() ? renderFollowBtn(ctrl, row) : [],
          ]),
          m("span", { class: pointsCls }, [
            m("span", Handlebars.helpers.intcomma(row.points())),
          ]),
        ]);
      }),
    );
  }

  function renderFollowBtn(ctrl, row) {
    if (row.id() === M.user.id) return [];

    const following = m(
      "a",
      {
        onclick: _.partial(ctrl.toggleFollow, row),
        onmouseenter() {
          row.hovering(true);
        },
        onmouseout() {
          row.hovering(false);
        },
      },
      row.hovering() ? _t("unfollow?") : _t("following"),
    );

    const notFollowing = m(
      "a",
      { onclick: _.partial(ctrl.toggleFollow, row) },
      _t("follow"),
    );

    return [row.following() ? following : notFollowing];
  }

  function renderLeaderboardControls(ctrl) {
    return m(
      ".leaderboard-controls",
      m("ul.btn-group", [
        m(
          `li.btn.btn-small${ctrl.vm.period() === "week" ? ".active" : ""}`,
          { onclick: _.partial(ctrl.setPeriod, "week").bind(ctrl) },
          _t("Week"),
        ),
        m(
          `li.btn.btn-small${ctrl.vm.period() === "month" ? ".active" : ""}`,
          { onclick: _.partial(ctrl.setPeriod, "month").bind(ctrl) },
          _t("Month"),
        ),
        m(
          `li.btn.btn-small${ctrl.vm.period() === "alltime" ? ".active" : ""}`,
          { onclick: _.partial(ctrl.setPeriod, "alltime").bind(ctrl) },
          _t("All Time"),
        ),
      ]),
    );
  }

  function renderLoadMore(ctrl) {
    if (
      ctrl.vm.rows().length === 0 ||
      ctrl.vm.rows().length % ctrl.vm.howMany() !== 0 ||
      ctrl.vm.hasPageSet()
    )
      return [];

    return m(".leaderboard-link", [
      m(
        "a",
        {
          onclick() {
            ctrl.fetchRows();
          },
        },
        _t("More"),
      ),
    ]);
  }

  M.newLeaderboard = (opts) => {
    const leaderboard = {
      vm: {
        fullBoardUrl: m.prop(opts.fullBoardUrl),
        howMany: m.prop(opts.howMany || 10),
        showTabs: m.prop(opts.showTabs),
        showFollow: m.prop(opts.showFollow),
        period: m.prop("week"),
        largeBoard: m.prop(opts.large),
        offset: m.prop(0),
        rows: m.prop([]),
        hasPageSet: m.prop(window.location.href.indexOf("?page=") > -1),
      },
    };

    leaderboard.controller = () => {
      const ctrl = {
        vm: leaderboard.vm,
        fetchRows() {
          const { vm } = this;
          let offset = vm.rows().length;
          let howMany = vm.howMany();
          const hasPageSet = vm.hasPageSet();

          const existingRows = vm.rows();

          // If we have rows and the last one is the current user
          // and the position of the user is greater than the number of rows
          // user has been injected
          const lastRow = existingRows[existingRows.length - 1];
          const userInjected =
            lastRow &&
            lastRow.id() === M.user.id &&
            lastRow.position() > existingRows.length;

          if (userInjected) {
            // pop user from end
            const me = existingRows.pop();
            // cover the row we have just popped
            offset -= 1;
            // if we are going to be returned in next fetch then return 1 more to cover injection
            if (me && me.position() < existingRows.length + vm.howMany())
              howMany += 1;
          }

          let query = sprintf("?period=%s&how_many=%d", vm.period(), howMany);
          if (hasPageSet) {
            const page = Number.parseInt(
              window.location.href.match(/page=([0-9])/)[1],
            );
            if (page > 0) offset = page * howMany - howMany;
          }

          if (offset !== 0) {
            query += sprintf("&offset=%d", offset);
          }

          m.request({
            method: "GET",
            url: opts.endPoint + query,
            unwrapSuccess(response) {
              return response.rows;
            },
            type: LeaderboardRow,
          }).then((rows) => {
            const href = `${window.location.pathname}?page=`;
            const ctrl_offset = offset + 1;
            const ctrl_howMany = howMany - 1;

            if (ctrl_offset > ctrl_howMany) {
              const prevPage =
                Number.parseInt((ctrl_offset - ctrl_howMany) / ctrl_howMany) +
                1;
              const link_prev = $("head").find('link[rel="prev"]');

              if (link_prev.length > 0) {
                link_prev.attr("href", href + prevPage);
              } else {
                $("head").append(
                  $("<link>")
                    .attr("rel", "prev")
                    .attr("href", href + prevPage),
                );
              }
            }

            if (
              !(vm.rows().length === 0) ||
              !(vm.rows().length % ctrl.vm.howMany() !== 0)
            ) {
              const nextPage =
                Number.parseInt((ctrl_offset + ctrl_howMany) / ctrl_howMany) +
                1;
              const link_next = $("head").find('link[rel="next"]');

              if (link_next.length > 0) {
                link_next.attr("href", href + nextPage);
              } else {
                $("head").append(
                  $("<link>")
                    .attr("rel", "next")
                    .attr("href", href + nextPage),
                );
              }
            }

            if (offset === 0) {
              return vm.rows(rows);
            }

            // user position is greater than all rows loaded
            if (
              userInjected &&
              me.position() > existingRows.length + rows.length
            )
              rows.push(me);
            // else will have been returned in rows
            vm.rows(existingRows.concat(rows));
          });
        },
        toggleFollow(row) {
          m.post({
            url: "/ajax/mempal_add/",
            data: { to_user_id: row.id() },
          }).then(
            () => {
              row.following(!row.following());
            },
            (data) => {
              M.modal.error(data.error);
            },
          );
        },
        setPeriod(period) {
          this.vm.period(period);
          this.vm.rows([]);
          this.fetchRows();
        },
      };

      ctrl.fetchRows(0);
      return ctrl;
    };

    leaderboard.view = (ctrl) =>
      m(".leaderboard", [
        m(".leaderboard-header", [
          m("span.leaderboard-image"),
          m("span.leaderboard-text", _t("Leaderboard")),
        ]),

        ctrl.vm.showTabs() ? renderLeaderboardControls(ctrl) : [],

        m(".leaderboard-content", [
          renderLeaderboardRows(ctrl),
          ctrl.vm.largeBoard()
            ? renderLoadMore(ctrl)
            : m(".leaderboard-link", [
                m("a", { href: ctrl.vm.fullBoardUrl() }, _t("More")),
              ]),
        ]),
      ]);

    return leaderboard;
  };
};
