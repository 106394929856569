module.exports = ($, M, i18n) => {
  const options = {
    showKeyIfEmpty: true,
    // languages
    lng: M.LANG,
    load: "current",
    // can load 2 json files
    // https://www.i18next.com/principles/fallback#fallback-to-different-languages
    fallbackLng: "en",
    // separators
    keyseparator: "&&&&&",
    nsseparator: "&&&&&&",
    // resource store
    resGetPath: M.STATIC_URL + M.urls.locale,
  };

  // shortcut
  window._t = i18n.t;
  window.plural = require("plugins/i18n/pluralise")(sprintf);

  return (callback) => {
    i18n.init(options, () => {
      // trigger callback passed to this function, if it exists
      if (callback) {
        callback();
      }

      $(document).ready(() => {
        if (M.renderer) {
          M.renderer.load(M.templates);
        }
      });
    });
  };
};
