module.exports = ($, M) => {
  // Setup
  M.profile = {};

  $.extend(true, M.profile, {
    update_json_setting(key, value, callback) {
      $.ajax({
        url: "/ajax/accounts/update_setting/",
        type: "POST",
        data: {
          key,
          value,
        },
        success(res) {
          M.user.profile.settings[key] = value;
          if (typeof callback === "function") {
            callback(res);
          }
        },
      });
    },
  });
};
