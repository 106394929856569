module.exports = ($, M, _) => {
  /*
     Function to lazy-load javascript modules when required. If the variable
     mentioned in string "variable" is not defined, it will load the given
     script url, then run function "done", else it will immediately run
     "done".
     */
  $.loadIfNDefScript = (where, variable, url, done) => {
    if (!_.has(where, variable)) {
      $.ajax({
        dataType: "script",
        cache: true,
        url: M.STATIC_URL + url,
        success: done,
      });
    } else {
      done();
    }
  };
};
