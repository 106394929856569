module.exports = ($, videojs) => {
  $(document).on("click", ".video-container .video-player", function () {
    const $self = $(this);
    if ($self.children("video").length === 0)
      videojs($self.attr("id"), {
        autoplay: true,
        controls: true,
        preload: "auto",
      });
  });
};
