module.exports = function helpers($, _, Handlebars, M) {
  Handlebars.registerHelper("compare", function (lvalue, rvalue, options) {
    // biome-ignore lint/style/noArguments: There's no obvious way to otherwise check this
    if (arguments.length < 3)
      throw new Error("Handlerbars Helper 'compare' needs 2 parameters");

    const operator = options.hash.operator || "==";

    const operators = {
      "==": (l, r) => {
        // biome-ignore lint/suspicious/noDoubleEquals:
        return l == r;
      },
      "===": (l, r) => l === r,
      "!=": (l, r) => {
        // biome-ignore lint/suspicious/noDoubleEquals:
        return l != r;
      },
      "!==": (l, r) => l !== r,
      "<": (l, r) => l < r,
      ">": (l, r) => l > r,
      "<=": (l, r) => l <= r,
      ">=": (l, r) => l >= r,
      typeof(l, r) {
        // biome-ignore lint/suspicious/useValidTypeof:
        return typeof l === r;
      },
    };

    if (!operators[operator])
      throw new Error(
        `Handlerbars Helper 'compare' doesn't know the operator ${operator}`,
      );

    const result = operators[operator](lvalue, rvalue);

    if (result) {
      return options.fn(this);
    }
    return options.inverse(this);
  });

  Handlebars.registerHelper("isNonzero", function (p, options) {
    if (typeof p === "number" && p !== 0) {
      return options.fn(this);
    }
    return options.inverse(this);
  });

  Handlebars.registerHelper("timesince", (date) => {
    try {
      return $.timeago(date);
    } catch (e) {
      return "";
    }
  });

  Handlebars.registerHelper("logContext", function () {
    if (window.console) {
      window.console.log(this);
    }
    return "";
  });

  Handlebars.registerHelper("absnumber", (number) => {
    if (!_.isNumber(number)) {
      return "NaN";
    }
    if (number < 0) {
      return `${number}`;
    }
    return `+${number}`;
  });

  // TODO: replace this with numeral.js
  Handlebars.registerHelper("intcomma", (value) => {
    const origValue = String(value);
    const newValue = origValue.replace(/^(-?\d+)(\d{3})/, "$1,$2");
    if (origValue === newValue) {
      return newValue;
    }
    return Handlebars.helpers.intcomma(newValue);
  });

  Handlebars.registerHelper("pluralize", (string, number) => {
    if (number === 1) {
      return string;
    }
    return `${string}s`;
  });

  Handlebars.registerHelper(
    "safeValue",
    (string) => new Handlebars.SafeString(string),
  );

  Handlebars.registerHelper("cssClasses", (classes) => {
    if (_.isArray(classes)) {
      return ` ${classes.join(" ")}`;
    }
    return "";
  });

  Handlebars.registerHelper("intcommabig", (value) => {
    if (_.isNumber(value)) {
      if (value > -10000 && value < 10000) {
        return Handlebars.helpers.intcomma(value);
      }
      if (value > -1000 * 1000 && value < 1000 * 1000) {
        return `${Handlebars.helpers.intcomma(Math.round(value / 1000))}k`;
      }
      return `${Handlebars.helpers.intcomma(
        Math.round(value / (1000 * 1000)),
      )}m`;
    }
  });

  Handlebars.registerHelper("convertSeconds", (seconds) => {
    const isNegative = seconds < 0;
    let secondsInHour;

    if (!_.isNumber(seconds)) {
      return "";
    }

    if (isNegative) {
      seconds *= -1;
    }

    secondsInHour = seconds % 60;

    if (secondsInHour < 10) {
      secondsInHour = `0${secondsInHour}`;
    }
    const hours = Math.floor(seconds / 60);

    return `${(isNegative ? "-" : "") + hours}:${secondsInHour}`;
  });

  Handlebars.registerHelper("asset", (...args) => {
    const path = _.toArray(args).slice(0, -1).join("");
    return M.STATIC_URL + path;
  });

  Handlebars.registerHelper("for", (from, to, incr, block) => {
    let accum = "";
    for (let i = from; i < to; i += incr) accum += block.fn(i);
    return accum;
  });

  Handlebars.registerHelper("monthOptions", () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let month_options = "";
    const current_month = new Date().getMonth();
    for (let i = 0; i < 12; i++) {
      const selected = i === current_month ? 'selected="selected"' : "";
      const option = sprintf("%d - %s", i + 1, months[i]);
      month_options += sprintf(
        '<option value="%d" %s>%s</option>',
        i + 1,
        selected,
        option,
      );
    }

    return new Handlebars.SafeString(month_options);
  });

  Handlebars.registerHelper("yearOptions", () => {
    const current_year = new Date().getFullYear();
    let year_options = "";
    for (let i = 0; i < 11; i++) {
      const selected = i === 0 ? 'selected="selected"' : "";
      const option = sprintf("%d", current_year + i);
      year_options += sprintf(
        '<option value="%d" %s>%s</option>',
        current_year + i,
        selected,
        option,
      );
    }

    return new Handlebars.SafeString(year_options);
  });

  // trans
  Handlebars.registerHelper("_t", function transHelper(i18nKey, options) {
    if (_.size(options.hash) > 0) {
      const context = _.has(options.hash, "context")
        ? options.hash.context
        : this;
      const opts = i18n.functions.extend(options.hash, context);

      if (options.fn) opts.defaultValue = options.fn(context);

      return new Handlebars.SafeString(_t(i18nKey, opts));
    }

    return new Handlebars.SafeString(_t(i18nKey));
  });

  // math: http://jsfiddle.net/mpetrovich/wMmHS/
  Handlebars.registerHelper("math", (lvalue, operator, rvalue, options) => {
    lvalue = Number.parseFloat(lvalue);
    rvalue = Number.parseFloat(rvalue);

    return {
      "+": lvalue + rvalue,
      "-": lvalue - rvalue,
      "*": lvalue * rvalue,
      "/": lvalue / rvalue,
      "%": lvalue % rvalue,
    }[operator];
  });

  Handlebars.registerHelper("truncate", (str, len) => {
    if (str.length > len) {
      let new_str = str.substr(0, len + 1);

      while (new_str.length) {
        const ch = new_str.substr(-1);
        new_str = new_str.substr(0, -1);

        if (ch === " ") {
          break;
        }
      }

      if (new_str === "") {
        new_str = str.substr(0, len);
      }

      return new Handlebars.SafeString(`${new_str}...`);
    }
    return str;
  });

  Handlebars.registerHelper("plainHtml", function stringHandler(str) {
    // Escape expression
    let html = Handlebars.Utils.escapeExpression(str);

    // Replace known whitelisted tags
    const tags = ["strong", "p", "br", "br ", "em", "a"];
    for (const tag of tags) {
      html = html.replace(new RegExp(`&lt;${tag}&gt;`, "g"), `<${tag}>`);
      html = html.replace(new RegExp(`&lt;/${tag}&gt;`, "g"), `</${tag}>`);
      html = html.replace(new RegExp(`&lt;${tag}/&gt;`, "g"), `<${tag}/>`);

      if (tag === "a") {
        // Check that links do not point away to malicious sites
        // &#x3D corresponds to = symbol
        const safeReplacer = (_match, hrefCapture) => {
          const safeMemriseLink = /^href&#x3D;&quot;https:\/\/memrise.com/g;
          const safeCommunityLink =
            /^href&#x3D;&quot;https:\/\/community\.memrise\.com/g;
          const safeZenDeskLink =
            /^href&#x3D;&quot;https:\/\/memrise.zendesk.com/g;
          const isSafeMemriseLink = safeMemriseLink.test(hrefCapture);
          const isSafeCommunityLink = safeCommunityLink.test(hrefCapture);
          const isSafeZenDeskLink = safeZenDeskLink.test(hrefCapture);
          const isSafe =
            isSafeMemriseLink || isSafeCommunityLink || isSafeZenDeskLink;

          if (!isSafe) {
            throw new Error(
              `Failed to parse <a> tag due to presence of unsafe link: ${hrefCapture}`,
            );
          }

          const parsedHref = hrefCapture
            .replace("&#x3D;", "=") // replace the first = only...
            .replace(/&quot;/g, '"'); // ...and all "s
          return `<a ${parsedHref}>`; // convert the tags here too
        };
        html = html.replace(
          /&lt;a\s(href&#x3D;&quot;.*&quot;)\s?&gt;/g,
          safeReplacer,
        );
      }
    }

    // Going forward, we would like keys in phraseapp to use __tagName__ and __/tagName__
    html = html.replace(/__([A-Za-z/]+)__/g, (match, p1) => {
      if (p1.indexOf("script") === -1 && p1.indexOf("iframe") === -1) {
        return `<${p1}>`;
      }
      return match;
    });

    // Return escaped string
    return new Handlebars.SafeString(html);
  });

  return Handlebars;
};
