module.exports = ($, M) => {
  M.Whitebox = {
    defaults: {},
    centered_box(options, callback) {
      const opts = $.extend({}, this.defaults, options);
      M.renderer.ready(() => {
        const context = {
          content: opts.template,
          data: opts.data,
        };
        const html = M.renderer.render(opts.parent, context);
        const $wb = $(html).appendTo("body");
      });
    },
  };
};
