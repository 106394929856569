module.exports = ($) => {
  function getCookie(name) {
    return `; ${document.cookie}`.split(`; ${name}=`).pop().split(";")[0];
  }

  function initHeaderLangChanger() {
    const $headerLang = $(".header-lang-changer");
    const $wrapper = $(".lang-changer-wrapper");
    const $arrow = $(".header-dropdown-arrow", $headerLang);
    const $dd = $(".dropdown-menu", $headerLang);
    const $option = $("a", $dd);
    const $langChooser = $(".set-lang");
    const $originalLang = $('.dropdown-menu > li[class="active"] > a').data(
      "lang",
    );

    $wrapper.on("click", () => {
      $arrow.toggleClass("is-active");
    });

    $option.on("click", function () {
      const lang = $(this).data("lang");

      $.post("/setlang/", {
        language: lang,
      })
        .done(() => {
          // Locale in url if present prevents the page from being localized to the new chosen language
          const hrefWithoutLocale = location.href.replace(
            `/${$originalLang}/`,
            "/",
          );
          if (hrefWithoutLocale !== location.href) {
            location.replace(hrefWithoutLocale);
          } else {
            location.reload();
          }
        })
        .fail((res) => {
          if (res.status === 200 && lang === getCookie("memrise_lang")) {
            location.reload();
          }
          throw new Error("Failed to change language");
        });
    });

    $(document).click((e) => {
      const { target } = e;

      if (
        $arrow.hasClass("is-active") &&
        !$(target).is($("a", $langChooser)) &&
        !$(target).is($wrapper) &&
        !$(target).is($dd) &&
        !$(target).is($option.parent())
      ) {
        $arrow.removeClass("is-active");
      }
    });
  }
  initHeaderLangChanger();
};
