module.exports = (soundManager, M) => {
  soundManager.setup({
    url: `${M.STATIC_URL}flash/`,
    useHTML5Audio: true,
    debugMode: false,
    useConsole: false,
    debugFlash: false,
    consoleOnly: false,
    preferFlash: false,
    ontimeout() {
      if (window.console) {
        window.console.log("soundManager timed out");
      }
    },
  });
};
