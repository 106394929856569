/**
 * This file build and invoke all memrise's custom scripts needed for every page
 * on document ready.
 */
((M) => {
  // polyfills to silence sentry errors
  require("./utils/polyfills")();

  // i18n goes first
  const i18nInit = require("./configs/i18n")($, M, i18n);
  const cookieBannerInit = require("./apps/cookie-banner")($, M, i18n);
  i18nInit(cookieBannerInit);

  // Plugins
  require("./plugins/jquery/jquery.cursorPosition")($);
  require("./plugins/jquery/jquery.dataAttr")($, _);
  require("./plugins/jquery/jquery.getUrlVar")($);
  require("./plugins/jquery/jquery.loadIfNDefScript")($, M, _);
  require("./plugins/lodash/observer")(_);
  require("./plugins/handlebars/helpers")($, _, Handlebars, M);

  // Configs
  require("./configs/csrf")($, M, m, Cookies);
  require("./configs/sounds")(soundManager, M);
  require("./configs/moment-locales");
  window.KEY_CODES = require("./configs/key-codes");
  window.uuidV4 = require("uuid").v4;

  // Our global apps
  window.datetime = require("./apps/datetime")(_);
  M.renderer = require("./apps/renderer")($, M, _, Handlebars, markdown);
  require("./apps/header")($, M);
  require("./apps/video-player")($, videojs);
  require("./apps/audio-player")($, M, soundManager);
  require("./apps/social/sharing")($, M);

  // UI components
  require("./ui/buttons")($);
  require("./ui/modal")($, M);
  require("./ui/whitebox")($, M);
  require("./ui/lang-changer")($, M);

  // garden apps
  require("../../garden/static/garden/js/courses")(_, $, M);
  require("../../garden/static/garden/js/leaderboard")($, M, m, _);
  require("../../garden/static/garden/js/profile")($, M);
  require("../../garden/static/garden/js/users")($, M);
  require("../../garden/static/garden/js/learning-performance")($, M);
  require("../../garden/static/garden/js/ui/hovercard")(_, Class, $, M);
  require("../../garden/static/garden/js/ui/modal-slides")(
    _,
    Class,
    $,
    M,
    KEY_CODES,
  );
})(MEMRISE);
