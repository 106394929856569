module.exports = (_) => ({
  SECOND: 1000,
  MINUTE: 1000 * 60,
  HOUR: 1000 * 60 * 60,
  DAY: 1000 * 60 * 60 * 24,
  WEEK: 1000 * 60 * 60 * 24 * 7,

  now() {
    return new Date();
  },

  today() {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    return today;
  },

  time_since(date) {
    return this.now() - date;
  },

  timedelta(params) {
    let ret = 0;

    if (_.has(params, "seconds")) {
      ret += params.seconds * this.SECOND;
    }
    if (_.has(params, "minutes")) {
      ret += params.minutes * this.MINUTE;
    }
    if (_.has(params, "hours")) {
      ret += params.hours * this.HOUR;
    }
    if (_.has(params, "days")) {
      ret += params.days * this.DAY;
    }
    if (_.has(params, "weeks")) {
      ret += params.weeks * this.WEEK;
    }

    return ret;
  },

  getWeekdayName(day, short) {
    const weekdays = [
      _t("Sunday"),
      _t("Monday"),
      _t("Tuesday"),
      _t("Wednesday"),
      _t("Thursday"),
      _t("Friday"),
      _t("Saturday"),
    ];

    return short ? weekdays[day][0] : weekdays[day];
  },

  getMonthName(month) {
    const months = [
      _t("January"),
      _t("February"),
      _t("March"),
      _t("April"),
      _t("May"),
      _t("June"),
      _t("July"),
      _t("August"),
      _t("September"),
      _t("October"),
      _t("November"),
      _t("December"),
    ];

    return months[month];
  },
});
