module.exports = ($, M, soundManager) => {
  const AudioPlayer = function () {
    this.playing = false;
    this.currentUrl = null;

    this.play = function (options) {
      const self = this;
      const url = options.url || null;

      if (url === null) {
        return false;
      }

      if (this.setMute()) {
        return;
      }

      const sound = this.loadSound(url);
      if (!sound) {
        return;
      }

      this.stop();

      this.triggerSound({
        sound,
        onplay() {
          self.playing = true;
          self.currentUrl = url;
          if (options.onplay !== null) {
            options.onplay(url);
          }
        },
        onfinish() {
          self.playing = false;
          if (options.onfinish !== null) {
            options.onfinish(url);
          }
          if (self.whenFinishedCallback) {
            self.whenFinishedCallback();
            self.whenFinishedCallback = undefined;
          }
        },
      });
    };

    this.setMute = () => {
      const muted = $("body").hasClass("audio-muted");
      if (muted) {
        soundManager.mute();
        return true;
      }
      soundManager.unmute();
      return false;
    };

    this.loadSound = function (url) {
      let sound;
      if (soundManager?.ok() && soundManager.canPlayURL(url)) {
        this.currentUrl = url;
        // returns a sound from cache if ID exists
        sound = soundManager.createSound({
          id: url,
          url,
        });
      }
      return sound;
    };

    this.triggerSound = (options) => {
      options.sound.play({
        onplay: options.onplay,
        onfinish: options.onfinish,
        onstop: options.onfinish,
      });
    };

    this.stop = function () {
      soundManager.stopAll();
      this.playing = false;
      this.currentUrl = null;
      // Cancel timeout
      $.doTimeout("AudioPlayer-stopAfter");
    };

    this.stopAfter = function (milliseconds) {
      if (this.playing) {
        $.doTimeout("AudioPlayer-stopAfter", milliseconds, () => {
          this.stop();
        });
      }
    };

    this.whenFinished = function (callback) {
      // Whilst you can pass an onfinish to play(), we also accept a
      // callback after-the-fact
      if (!this.playing) {
        callback();
      } else {
        this.whenFinishedCallback = callback;
      }
    };
  };

  M.audioPlayer = new AudioPlayer();

  // Audio player handling.
  $(document)
    .on("memrise.audio-play", "a.audio-player", function () {
      const $this = $(this);
      M.audioPlayer.play({
        url: $this.attr("href"),
        onplay() {
          $this.addClass("playing");
        },
        onfinish() {
          $this.removeClass("playing");
        },
      });
    })
    .on("memrise.audio-stop", "a.audio-player", () => {
      M.audioPlayer.stop();
    })
    .on("mouseenter", "a.audio-player.audio-player-hover", function (e) {
      if (!MEMRISE.is_iOS_optimised) {
        $(this).trigger("memrise.audio-play");
      }
      e.preventDefault();
    })
    .on("click", "a.audio-player.audio-player-hover", function (e) {
      // iOS 5+ does not trigger mouseenter for events, and thus we transform
      // our hover audio players back into click ones
      if (MEMRISE.is_iOS_optimised) {
        $(this).trigger("memrise.audio-play");
      }
      e.preventDefault();
    });
};
