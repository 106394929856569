// Set up a global KEY_CODES object to make keydown handling easier.
module.exports = {
  BACKSPACE: 8,
  CAPS_LOCK: 20,
  COMMA: 188,
  CONTROL: 17,
  DELETE: 46,
  END: 35,
  ENTER: 13,
  ESCAPE: 27,
  HOME: 36,
  INSERT: 45,
  PAGE_DOWN: 34,
  PAGE_UP: 33,
  PERIOD: 190,
  SHIFT: 16,
  SPACE: 32,
  TAB: 9,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  ZERO: 48,
  ONE: 49,
  TWO: 50,
  THREE: 51,
  FOUR: 52,
  FIVE: 53,
  SIX: 54,
  SEVEN: 55,
  EIGHT: 56,
  NINE: 57,
  NUMPAD_ADD: 107,
  NUMPAD_DECIMAL: 110,
  NUMPAD_DIVIDE: 111,
  NUMPAD_ENTER: 108,
  NUMPAD_MULTIPLY: 106,
  NUMPAD_SUBTRACT: 109,
  NUMPAD_ZERO: 96,
  NUMPAD_ONE: 97,
  NUMPAD_TWO: 98,
  NUMPAD_THREE: 99,
  NUMPAD_FOUR: 100,
  NUMPAD_FIVE: 101,
  NUMPAD_SIX: 102,
  NUMPAD_SEVEN: 103,
  NUMPAD_EIGHT: 104,
  NUMPAD_NINE: 105,
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,
};
