module.exports = ($, M) => {
  M.learning_performance = {};

  const performance = M.learning_performance;
  const learningChart = null;
  let testingChart = null;

  $.extend(true, M.learning_performance, {
    initTestingGraph() {
      performance.initTestingDropdowns();
      performance.setTestingLazyLoading();
    },
    initTestingDropdowns() {
      $(".filters")
        .on("click", ".filter .dropdown-menu a", function (e) {
          e.preventDefault();
          const $this = $(this);
          const $filter = $this.closest(".filter");
          const $btn1 = $(".btn1", $filter);
          const filter = $this.data("filter");
          const params = {
            filter,
          };
          if ($this.data("id")) params.id = $this.data("id");

          $btn1.toggleClass("bgGreen");
          $this
            .parent("li")
            .addClass("active")
            .siblings()
            .removeClass("active");
          $btn1.text($(this).text());

          performance.loadTestingData(params, performance.updateTestingChart);
        })
        .on("click", ".btn1", function (e) {
          e.stopPropagation();
          $(this).siblings(".dropdown-toggle").click();
        });
    },

    setTestingLazyLoading() {
      if (performance.isVisible("#lazy-learning-insight")) {
        $(window).unbind("scroll");
        performance.loadTestingData(
          null,
          performance.initialTestingLoadCallback,
        );
      } else {
        $(window).scroll(() => {
          if (performance.isVisible("#lazy-learning-insight")) {
            $(window).unbind("scroll");
            performance.loadTestingData(
              null,
              performance.initialTestingLoadCallback,
            );
          }
        });
      }
    },
    isVisible(elem) {
      const winTop = $(window).scrollTop();
      const winBottom = winTop + $(window).height();
      const elemTop = $(elem).offset();
      if (elemTop === null) return false;
      return elemTop.top <= winBottom;
    },
    updateTestingChart(filteredData) {
      testingChart.dataProvider = filteredData.data;
      testingChart.validateData();
    },
    initialTestingLoadCallback(data) {
      const chartData = performance.setTestingData(data);
      testingChart = AmCharts.makeChart("chart-testing", {
        type: "xy",
        theme: "none",
        pathToImages: "https://www.amcharts.com/lib/3/images/",
        dataProvider: chartData,
        chartCursor: {},
        legend: {
          useGraphSettings: true,
          equalWidths: false,
          fontSize: 13,
          rollOverGraphAlpha: 0.15,
          switchable: true,
        },
        valueAxes: [
          {
            // x
            title: _t("Time of the day"),
            position: "bottom",
            autoGridCount: false,
            gridCount: 12,
            maximum: 23,
            minimum: 0,
          },
          {
            // y
            title: _t("Response time in seconds"),
            position: "left",
            autoGridCount: false,
            gridCount: 10,
            maximum: 20,
          },
        ],
        startDuration: 0.3,
        graphs: [
          {
            // correct
            id: "correct",
            balloonText: _t(
              "At __tag1__ your __br__ average response __br__ time is __tag2__.",
              {
                tag1: "<b>[[yCorrectBalloon]]</b>",
                br: "<br />",
                tag2: "<b>[[y]]s</b>",
              },
            ),
            bullet: "bubble",
            lineColor: "#83c53d",
            lineAlpha: 0,
            title: _t("Correct"),
            xField: "xCorrect",
            yField: "yCorrect",
            valueField: "amountCorrect",
            fillAlphas: 0,
            bulletBorderAlpha: 0.2,
            maxBulletSize: 20,
            minBulletSize: 4,
          },
          {
            // nearly correct
            id: "nearlyCorrect",
            balloonText: _t(
              "At __tag1__ your __br__ average response __br__ time is __tag2__.",
              {
                tag1: "<b>[[yNearlyCorrectBalloon]]</b>",
                br: "<br />",
                tag2: "<b>[[y]]s</b>",
              },
            ),
            bullet: "bubble",
            lineColor: "#e89c06",
            lineAlpha: 0,
            title: _t("Nearly correct"),
            xField: "xNearlyCorrect",
            yField: "yNearlyCorrect",
            valueField: "amountNearlyCorrect",
            fillAlphas: 0,
            bulletBorderAlpha: 0.2,
            maxBulletSize: 20,
            minBulletSize: 4,
          },
          {
            // incorrect
            id: "incorrect",
            balloonText: _t(
              "At __tag1__ your __br__ average response __br__ time is __tag2__.",
              {
                tag1: "<b>[[yIncorrectBalloon]]</b>",
                br: "<br />",
                tag2: "<b>[[y]]s</b>",
              },
            ),
            bullet: "bubble",
            lineColor: "#bb2e25",
            lineAlpha: 0,
            title: _t("Incorrect"),
            xField: "xIncorrect",
            yField: "yIncorrect",
            valueField: "amountIncorrect",
            fillAlphas: 0,
            bulletBorderAlpha: 0.2,
            maxBulletSize: 20,
            minBulletSize: 4,
          },
        ],
        chartScrollbar: {
          backgroundColor: "#15a1ec",
          updateOnReleaseOnly: false,
        },
      });
      $("#chart-testing")
        .css("visibility", "visible")
        .parent()
        .find(".loading-spinner")
        .hide();
    },
    loadTestingData(params, callback) {
      $.ajax({
        type: "GET",
        url: "/ajax/metrics/fancy_tests_graph/",
        async: true,
        data: params,
        success(data) {
          if (typeof callback === "function") callback(data.data);
        },
      });
    },
    initAttendanceDataGrid() {
      $.ajax({
        type: "GET",
        url: "/ajax/metrics/learning_streak_graph/",
        success(data) {
          // Create grid
          const grid = $("#attendance-grid");
          grid.html("");
          new MEMRISE.charts.AttendanceGrid({
            elem: grid,
            data: data.attendance_data,
            dateField: "day",
            countField: "num_events",
            countName: "Learning Event",
            firstDate: data.start_day,
            lastDate: data.today,
          });

          // Create labels
          const label1 = $("#attendance-grid-label1");
          const label2 = $("#attendance-grid-label2");
          const label3 = $("#attendance-grid-label3");
          let days;
          let val;

          val = data.total_days_learned;
          days = val === 1 ? _t("day") : _t("days");
          label1
            .show()
            .find(".body")
            .html(
              `<span class="large">${val}</span><span class="day">${days}</span>`,
            );

          val = data.longest_streak;
          days = val === 1 ? _t("day") : _t("days");
          label2
            .show()
            .find(".body")
            .html(
              `<span class="large">${val}</span><span class="day">${days}</span>`,
            );

          val = data.current_streak;
          days = val === 1 ? _t("day") : _t("days");
          label3
            .show()
            .find(".body")
            .html(
              `<span class="large">${val}</span><span class="day">${days}</span>`,
            );
        },
      });
    },
    initMostLearntCoursesChart(courseData) {
      const chart = AmCharts.makeChart("chart-coursetime", {
        type: "pie",
        theme: "none",
        minRadius: 100,
        dataProvider: courseData,
        valueField: "time",
        titleField: "course",
        labelsEnabled: false,
        colors: [
          "#15a1ec",
          "#aa5f86",
          "#e89c06",
          "#83c53d",
          "#ff1605",
          "#aa5f10",
        ],
        pieX: "20%",
        pieY: "44%",
        legend: {
          position: "absolute",
          width: 300,
          top: 10,
          right: 120,
          verticalGap: 20,
          markerType: "square",
          markerSize: 32,
          valueText: "[[none]]",
        },
        balloonText:
          "<span style='font-size:10px'><b>[[course]]</b><br />[[timehms]]</span>",
      });

      $.each(courseData, (k, v) => {
        const url = v.id ? `/community/course/${v.id}` : "/community/courses/";
        const text = _t("Learn");
        $(".whitebox.buttons .btns").append(
          `<a href='${url}' class='btn btn-success btn-green'>${text}</a>`,
        );
      });
    },
    setTestingData(data) {
      const correct = [];
      const nearly = [];
      const incorrect = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].correct_resp_time !== 0) {
          correct.push({
            yCorrect: data[i].correct_resp_time,
            xCorrect: data[i].x_axis,
            yCorrectBalloon: data[i].time,
            amountCorrect: data[i].correct,
          });
        }
        if (data[i].partially_correct_resp_time !== 0) {
          nearly.push({
            yNearlyCorrect: data[i].partially_correct_resp_time,
            xNearlyCorrect: data[i].x_axis,
            yNearlyCorrectBalloon: data[i].time,
            amountNearlyCorrect: data[i].partially_correct,
          });
        }
        if (data[i].incorrect_resp_time !== 0) {
          incorrect.push({
            yIncorrect: data[i].incorrect_resp_time,
            xIncorrect: data[i].x_axis,
            yIncorrectBalloon: data[i].time,
            amountIncorrect: data[i].incorrect,
          });
        }
      }
      const temp = correct.concat(nearly);
      return temp.concat(incorrect);
    },
  });
};
