module.exports = ($) => {
  // Short jQuery plugin for ? get vars
  $.extend({
    getUrlVar(name) {
      const regexS = `[\\?&]${name}=([^&#]*)`;
      const regex = new RegExp(regexS);
      const results = regex.exec(window.location.href);

      if (results === null) {
        return;
      }
      return results[1];
    },
  });
};
