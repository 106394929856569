module.exports = () => {
  // polyfill localstorage so that it doesn't return a sentry error
  // on supported browsers
  if (typeof localStorage === "undefined" || !localStorage) {
    window.localStorage = {
      _data: {},
      setItem(id, val) {
        this._data[id] = val;
        return this._data[id];
      },
      getItem(id) {
        return this._data[id];
      },
      removeItem(id) {
        return delete this._data[id];
      },
      clear() {
        this._data = {};
        return this._data;
      },
    };
  }
};
